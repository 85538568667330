import { AgChartTheme } from 'ag-charts-community';

export const ligthTheme: AgChartTheme = {
  baseTheme: 'ag-default',
  overrides: {
    'range-area': {
      axes: {
        number: {
          crosshair: {
            label: {
              enabled: true,
              className: 'sbscrosshair-dark',
              renderer: (e) => {
                return {
                  text: e.value.toLocaleString('de-DE'),
                  opacity: 1,
                };
              },
            },
          },
        },
        time: {
          crosshair: {
            label: {
              enabled: true,
              className: 'sbscrosshair-dark',
            },
          },
        },
      },
    },
  },
};

export const darkTheme: AgChartTheme = {
  baseTheme: 'ag-default-dark',
  overrides: {
    common: {
      background: {
        fill: '#171A1C',
      },
      tooltip: {
        class: 'darkChart',
      },
    },
    'range-area': {
      axes: {
        number: {
          crosshair: {
            label: {
              enabled: true,
              className: 'sbscrosshair-dark',
              renderer: (e) => {
                return {
                  text: e.value.toLocaleString('de-DE'),
                  opacity: 1,
                };
              },
            },
          },
        },
        time: {
          crosshair: {
            label: {
              enabled: true,
              className: 'sbscrosshair-dark',
            },
          },
        },
      },
    },
  },
};
