import { AgChartTheme } from 'ag-charts-community';
// import styled from './ImbalanceSignal.module.css';

export const ligthTheme: AgChartTheme = {
  baseTheme: 'ag-default',
};

export const darkTheme: AgChartTheme = {
  baseTheme: 'ag-default-dark',
  overrides: {
    common: {
      background: {
        fill: '#171A1C',
      },
      tooltip: {
        class: 'darkChart',
      },
    },
  },
};
