import { CountryFlag, LinkToMessage, StatusIndicator } from 'components';
import { FC, useState } from 'react';
import { CountryCode, MessageStatus, SortType } from 'utils/types/generalTypes';
import { useDashboardMessageStatusesByDay } from '../dashboardApi';
import { formatFromUtc } from 'utils/dateFormating';
import { useAppSelector } from 'store/hooks';
import { AgGridReact, CustomCellRendererProps } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';

ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule]);

interface MessageStatusTableProps {
  date: Date;
}

const AreaRenderer = (params: CustomCellRendererProps) => {
  return <CountryFlag countryCode={params.data.area.country.isoAlpha2Code as CountryCode} />;
};

const MessageStatusRenderer = (params: CustomCellRendererProps) => {
  return <StatusIndicator status={params.data.area.status as MessageStatus} />;
};

const LastChangeCellRenderer = (params: CustomCellRendererProps, timeZone?: string) => {
  const [date, hour] =
    params.value !== null
      ? formatFromUtc(new Date(`${params.value}Z`), timeZone).split(' ')
      : [null, null];

  return (
    <>
      {date} {hour}
    </>
  );
};

export const MessageStatusTable: FC<MessageStatusTableProps> = ({ date }) => {
  const [activeSort] = useState<SortType>({
    name: '',
    direction: 'none',
  });

  const { timezone: userTimezone } = useAppSelector((store) => store.globalSlice);

  const { data } = useDashboardMessageStatusesByDay(date, activeSort);

  const columnDefs = [
    {
      field: 'area.country.isoAlpha2Code',
      headerName: 'Area',
      checkboxSelection: false,
      cellRenderer: AreaRenderer,
      maxWidth: 100,
      rowGroup: true,
      enableRowGroup: true,
      showRowGroup: true,
    },
    {
      field: 'messageDefinitionDescription',
      headerName: 'Message',
      sortable: false,
      flex: 1,
    },
    { field: 'status', cellRenderer: MessageStatusRenderer, sortable: false, maxWidth: 80 },
    {
      field: 'lastChange',
      headerName: 'Last change',
      maxWidth: 180,
      minWidth: 240,
      sortable: true,
      cellRenderer: (params: CustomCellRendererProps) =>
        LastChangeCellRenderer(params, userTimezone?.timeZone),
    },
    {
      headerName: 'Action',
      field: 'messageId',
      sortable: false,
      maxWidth: 80,
      cellRenderer: (params: CustomCellRendererProps) => {
        return params.value && <LinkToMessage link={`/app/history/message/${params.value}`} />;
      },
    },
  ];

  return (
    <div className={'ag-theme-quartz'} style={{ height: '40vh' }}>
      <AgGridReact
        rowData={data?.content}
        groupDisplayType={'groupRows'}
        groupRowRendererParams={{ checkbox: true, rowDrag: true }}
        rowGroupPanelShow={'always'}
        columnDefs={columnDefs}
        pagination={false}
      />
    </div>
  );
};
