import { useContext, useEffect, useState } from 'react';
import { useDataCollector } from '../GTSAdminPanelApi';
import { DataCollector } from '../types';
import { AgGridReact, CustomCellRendererProps } from '@ag-grid-community/react';
// import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ColDef } from '@ag-grid-community/core';
import { ClassicButton, FormTitle, HeadingBar, VARIANT } from 'components';
import { ThemeFunctionsContext } from 'App';
import ligthTheme from 'features/GTSPanel/components/SystemBalanceSignalTable/themes/ligthTheme';
import darkTheme from 'features/GTSPanel/components/SystemBalanceSignalTable/themes/darkTheme';
import styled from 'styled-components';
import UtcDateFormatCellRenderer from './UtcDateFormatCellRenderer';
import { useAppSelector } from 'store/hooks';

export const AgGridContainer = styled.div`
  padding: 1.5625rem 5rem;
  width: 100%;
  margin-bottom: 3rem;
  height: 55vh;
`;

const SuccessMessage = styled.span`
  color: green;
`;

const ConfigurationDataCollector = () => {
  const { themeName } = useContext(ThemeFunctionsContext) || {};
  const [gridTheme, setGridTheme] = useState(ligthTheme);

  useEffect(() => {
    if ('lightTheme' === themeName) {
      setGridTheme(ligthTheme);
    } else {
      setGridTheme(darkTheme);
    }
  }, [themeName]);

  const { timezone: userTimezone } = useAppSelector((store) => store.globalSlice);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [configurationData, setConfigurationData] = useState<DataCollector>({} as DataCollector);
  const [configurationDirtyData, setConfigurationDirtyData] = useState<string[]>([]);

  /**
   * Configuraions
   */
  const { data, isLoading, isSuccess } = useDataCollector();
  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      setConfigurationData(data);
    }
  }, [data, isLoading, isSuccess]);

  const [colDefs] = useState<ColDef<DataCollector>[]>([
    {
      field: 'fixedRate',
      headerName: 'Fixed rate[minutes]',
      suppressMovable: true,
      minWidth: 300,
      sortable: false,
    },
    {
      field: 'initialDelay',
      headerName: 'Initial delay[minutes]',
      suppressMovable: true,
      minWidth: 300,
      sortable: false,
    },
    {
      field: 'nextExecution',
      suppressMovable: true,
      sortable: false,
      flex: 1,
      cellRenderer: (params: CustomCellRendererProps) =>
        params && UtcDateFormatCellRenderer(params, userTimezone?.timeZone),
    },
  ]);

  return (
    <>
      <HeadingBar>
        <FormTitle>
          Data collection configuration
          {showSuccessMessage && <SuccessMessage>[Data was saved!]</SuccessMessage>}
        </FormTitle>
      </HeadingBar>

      <AgGridContainer className={'ag-theme-quartz'}>
        {!isLoading && (
          <AgGridReact rowData={[configurationData]} columnDefs={colDefs} theme={gridTheme} />
        )}
        <ClassicButton
          width="14.375rem"
          variant={VARIANT.PRIMARY}
          // onClick={() => history.push('/app/gts-admin-panel/certificate/create')}
          disabled={!isDirty}
          onClick={() => {
            alert('kek');
            // saveConfig({
            //   configurationData: configurationData,
            //   configurationDirtyData: configurationDirtyData,
            // });
          }}
        >
          Update
        </ClassicButton>
      </AgGridContainer>
    </>
  );
};

export default ConfigurationDataCollector;
