import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Control } from 'react-hook-form';
import { ControlledDatePicker } from 'components';

const DashboardHeadingRow = styled.div`
  width: 100%;
  display: flex;
  padding: 1.5625rem 0;
  justify-content: space-between;
`;

const Heading = styled.h1`
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 0.625rem;
`;

interface DashboardHeadingBarProps {
  dateControl: Control;
}

export const DashboardHeadingBar: FC<DashboardHeadingBarProps> = ({ dateControl }) => {
  const { t } = useTranslation('dashboard');
  return (
    <DashboardHeadingRow>
      <Heading>
        <>{t('title')} New</>
      </Heading>
      <ControlledDatePicker control={dateControl} name="selectedDate" />
    </DashboardHeadingRow>
  );
};
