import { BaseButton, StatusIndicator } from 'components';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { Triangle } from 'assets/icons';
import { MessageCompareCarousel } from './MessageCompareCarousel';
import { GasDayHourDefinitionType } from 'utils/types';
import { TimeSeriesMessageValuesDto } from 'utils/types/generalTypes';

const MessageCompareViewContainer = styled.div<{ isExpanded: boolean; isExcluded: boolean }>`
  width: 100%;
  border: 1px solid ${({ isExpanded, theme }) => (isExpanded ? theme.primary : theme.lightGrey)};
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  background-color: ${({ isExcluded, theme }) =>
    isExcluded ? theme.lightGrey : theme.messagePreviewBg};
`;

const MessageDetailsBar = styled.div`
  display: flex;
`;

const ExpandButton = styled(BaseButton)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  height: 3rem;
`;
// TODO: We could make this icon more universal an shared
const Icon = styled(Triangle)<{ $isOpen: boolean }>`
  transform: rotate(${({ $isOpen }) => ($isOpen ? '90deg' : '-90deg')});
  width: 0.5625rem;
  path {
    fill: ${({ theme }) => theme.baseBlack};
  }
`;
const InfoContainer = styled.div`
  display: flex;
  margin-right: 3rem;
  align-items: center;
`;

const StyledLabel = styled.label<{ fontSize?: string; isExcluded: boolean }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '1rem')};
  margin-right: 0.5rem;
  font-weight: 600;
  background-color: ${({ isExcluded, theme }) =>
    isExcluded ? theme.lightGrey : theme.messagePreviewBg};
`;

const ExternalCounterpartyNameContainer = styled.span`
  display: flex;
  margin-left: 1rem;
`;

interface MessageCompareViewProps {
  value: TimeSeriesMessageValuesDto;
  gasDayHourDefinition: GasDayHourDefinitionType;
}

export const MessageCompareView: FC<MessageCompareViewProps> = ({ value }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    externalAccount,
    internalHourlyValues,
    externalHourlyValues,
    messageStatus,
    excludeInOutgoingMessage,
    externalAccountCounterPartyCode,
    externalAccountCounterPartyName,
  } = value;
  return (
    <MessageCompareViewContainer isExpanded={isExpanded} isExcluded={false}>
      <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
        <MessageDetailsBar>
          <InfoContainer>
            <StyledLabel isExcluded={excludeInOutgoingMessage}>Status:</StyledLabel>
            <StatusIndicator status={messageStatus} />
          </InfoContainer>
          <InfoContainer>
            <StyledLabel isExcluded={false}>External account:</StyledLabel>
            {externalAccount}
            <ExternalCounterpartyNameContainer title={externalAccountCounterPartyName}>
              <StyledLabel isExcluded={false}>Counterparty:</StyledLabel>
              {externalAccountCounterPartyCode}
            </ExternalCounterpartyNameContainer>
          </InfoContainer>
          <InfoContainer>
            <StyledLabel isExcluded={excludeInOutgoingMessage}>
              Single sided nomination:
            </StyledLabel>
            {excludeInOutgoingMessage ? 'True' : 'False'}
          </InfoContainer>
        </MessageDetailsBar>
        <Icon $isOpen={isExpanded} />
      </ExpandButton>
      {isExpanded && (
        <MessageCompareCarousel
          internalHourlyValues={internalHourlyValues}
          externalHourlyValues={externalHourlyValues}
          gasDayHourDefinition={value.gasDayHourDefinition}
        />
      )}
    </MessageCompareViewContainer>
  );
};
