'use strict';

import { AgGridReact } from '@ag-grid-community/react';
import { ColDef } from '@ag-grid-community/core';

import { SystemBalanceSignal, SystemBalanceSignalTableResponse } from 'features/GTSPanel/types';
import { useContext, useEffect, useState } from 'react';
import { useSystemBalanceSignalTable } from 'features/GTSPanel/gtsdataApi';
import { Loader } from 'components';
import styles from '../../GTSPanel.module.css';
import { formatDateFromUtc, formatFromUtc, formatTimeFromUtc } from 'utils/dateFormating';
import { useAppSelector } from 'store/hooks';
import darkTheme from './themes/darkTheme';
import ligthTheme from './themes/ligthTheme';
import { ThemeFunctionsContext } from 'App';

const SystemBalanceSignalTable = () => {
  const { isLoading, data, isSuccess } = useSystemBalanceSignalTable();

  const [tableData, setTableData] = useState<SystemBalanceSignalTableResponse>({
    lastUpdated: new Date().toISOString(),
    systemBalanceSignalTable: [],
  });

  const { themeName } = useContext(ThemeFunctionsContext) || {};
  const [gridTheme, setGridTheme] = useState(ligthTheme);

  useEffect(() => {
    if ('lightTheme' === themeName) {
      setGridTheme(ligthTheme);
    } else {
      setGridTheme(darkTheme);
    }
  }, [themeName]);

  const { timezone } = useAppSelector((store) => store.globalSlice);

  const [columnDefs] = useState<ColDef<SystemBalanceSignal>[]>([
    {
      field: 'sbsDateTime',
      headerName: 'Date',
      width: 115,
      valueFormatter: (sbsDateTime) => {
        return formatDateFromUtc(sbsDateTime.value, timezone?.timeZone);
      },
    },
    {
      field: 'sbsDateTime',
      headerName: 'Time',
      width: 90,
      valueFormatter: (sbsDateTime) => {
        return formatTimeFromUtc(sbsDateTime.value, timezone?.timeZone);
      },
    },
    {
      field: 'positionEnergyAmount',
      headerName: 'Accountable',
      width: 130,
      cellClass: `${styles.textLeft}`,
      valueFormatter: (cellInfo) => {
        return cellInfo.value.toLocaleString('de-DE');
      },
    },
    {
      field: 'sumHelpersEnergyAmount',
      headerName: 'Sum Helpers',
      width: 130,
      cellClass: `${styles.textLeft}`,
      valueFormatter: (cellInfo) => {
        return cellInfo.value.toLocaleString('de-DE');
      },
    },
    {
      field: 'sumCausersEnergyAmount',
      headerName: 'Sum Causers',
      width: 130,
      cellClass: `${styles.textLeft}`,
      valueFormatter: (cellInfo) => {
        return cellInfo.value.toLocaleString('de-DE');
      },
    },
    {
      field: 'bufferZone03BorderMin',
      headerName: 'Low border orange zone',
      headerTooltip: 'Low border orange zone',
      width: 190,
      cellClass: `${styles.orange} ${styles.textLeft}`,
      valueFormatter: (cellInfo) => {
        return cellInfo.value.toLocaleString('de-DE');
      },
    },
    {
      field: 'bufferZone02BorderMin',
      headerName: 'Low border light green zone',
      headerTooltip: 'Low border light green zone',
      width: 190,
      cellClass: `${styles.lightGreen} ${styles.textLeft}`,
      valueFormatter: (cellInfo) => {
        return cellInfo.value.toLocaleString('de-DE');
      },
    },
    {
      field: 'bufferZone01BorderMin',
      headerName: 'Low border dark green zone',
      headerTooltip: 'Low border dark green zone',
      width: 190,
      cellClass: `${styles.darkGreen} ${styles.textLeft}`,
      valueFormatter: (cellInfo) => {
        return cellInfo.value.toLocaleString('de-DE');
      },
    },
    {
      field: 'bufferZone01BorderMax',
      headerName: 'Upper border dark green zone',
      headerTooltip: 'Upper border dark green zone',
      width: 190,
      cellClass: `${styles.darkGreen} ${styles.textLeft}`,
      valueFormatter: (cellInfo) => {
        return cellInfo.value.toLocaleString('de-DE');
      },
    },
    {
      field: 'bufferZone02BorderMax',
      headerName: 'Upper border light green zone',
      headerTooltip: 'Upper border light green zone',
      width: 190,
      cellClass: `${styles.lightGreen} ${styles.textLeft}`,
      valueFormatter: (cellInfo) => {
        return cellInfo.value.toLocaleString('de-DE');
      },
    },
    {
      field: 'bufferZone03BorderMax',
      headerName: 'Upper border orange zone',
      headerTooltip: 'Upper border orange zone',
      width: 190,
      cellClass: `${styles.orange} ${styles.textLeft}`,
      valueFormatter: (cellInfo) => {
        return cellInfo.value.toLocaleString('de-DE');
      },
    },
  ]);

  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      setTableData(data);
    }
  }, [isLoading, data, isSuccess]);

  return !isLoading && tableData ? (
    <div className={`${styles.gridReact}`}>
      <h1 className={styles.gridReactTitle}>
        System Balance Signal [Last updated {formatFromUtc(new Date(tableData.lastUpdated))}]
      </h1>
      <AgGridReact<SystemBalanceSignal>
        rowData={tableData.systemBalanceSignalTable}
        columnDefs={columnDefs}
        theme={gridTheme}
      />
    </div>
  ) : (
    <Loader />
  );
};

export default SystemBalanceSignalTable;
