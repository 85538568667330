import { useContext, useEffect, useState } from 'react';
import { useConfigproperty, useSaveConfigproperty } from '../GTSAdminPanelApi';
import { Configuration } from '../types';
import { AgGridReact } from '@ag-grid-community/react';
// import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ColDef, ISelectCellEditorParams } from '@ag-grid-community/core';
import { ClassicButton, FormTitle, HeadingBar, VARIANT } from 'components';
import { ThemeFunctionsContext } from 'App';
import ligthTheme from 'features/GTSPanel/components/SystemBalanceSignalTable/themes/ligthTheme';
import darkTheme from 'features/GTSPanel/components/SystemBalanceSignalTable/themes/darkTheme';
import styled from 'styled-components';

export const AgGridContainer = styled.div`
  padding: 1.5625rem 5rem;
  width: 100%;
  margin-bottom: 3rem;
  height: 55vh;
`;

const SuccessMessage = styled.span`
  color: green;
`;

const ConfigurationGrid = () => {
  const { themeName } = useContext(ThemeFunctionsContext) || {};
  const [gridTheme, setGridTheme] = useState(ligthTheme);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if ('lightTheme' === themeName) {
      setGridTheme(ligthTheme);
    } else {
      setGridTheme(darkTheme);
    }
  }, [themeName]);
  const [configurationData, setConfigurationData] = useState<Configuration[]>([]);
  const [configurationDirtyData, setConfigurationDirtyData] = useState<string[]>([]);
  /**
   * Configuraions
   */
  const { data, isLoading, isSuccess } = useConfigproperty();

  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      setConfigurationData(data);
    }
  }, [data, isLoading, isSuccess]);
  const [isDirty, setIsDirty] = useState(false);

  const { mutate: saveConfig, isSuccess: isSuccessSaveConfig } = useSaveConfigproperty();

  const [colDefs] = useState<ColDef<Configuration>[]>([
    { field: 'key', suppressMovable: true, minWidth: 300, sortable: false },
    {
      field: 'value',
      suppressMovable: true,
      flex: 1,
      type: 'string',
      editable: true,
      // cellRendererSelector: (params) => {
      //   if (params.data && 'gts.service.licensed' === params.data.key) {
      //     return {
      //       component: 'agCheckboxCellRenderer',
      //       params: { value: !!('true' === params.data.value) },
      //     };
      //   }
      //   return {
      //     component: undefined,
      //   };
      // },
      cellEditorSelector: (params) => {
        setConfigurationDirtyData((state) => {
          return [...state, params.data.key];
        });

        if ('gts.service.licensed' === params.data.key) {
          return {
            component: 'agSelectCellEditor',
            params: {
              values: ['true', 'false'],
            } as ISelectCellEditorParams,
          };
        }

        return {
          component: 'agTextCellEditor',
        };
      },
      valueSetter: (params) => {
        const data = params.data;
        data.value = params.newValue;
        setIsDirty(true);
        return true;
      },
      sortable: false,
    },
    {
      field: 'description',
      suppressMovable: true,
      minWidth: 500,
      wrapText: true,
      autoHeight: true,
      sortable: false,
    },
  ]);

  useEffect(() => {
    if (isSuccessSaveConfig) {
      setIsDirty(false);
      setShowSuccessMessage(true);

      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 2000);
    }
  }, [isSuccessSaveConfig]);

  return (
    <>
      <HeadingBar>
        <FormTitle>
          Configuration {showSuccessMessage && <SuccessMessage>[Data was saved!]</SuccessMessage>}
        </FormTitle>
      </HeadingBar>

      <AgGridContainer className={'ag-theme-quartz'}>
        {!isLoading && (
          <AgGridReact rowData={configurationData} columnDefs={colDefs} theme={gridTheme} />
        )}
        <ClassicButton
          width="14.375rem"
          variant={VARIANT.PRIMARY}
          // onClick={() => history.push('/app/gts-admin-panel/certificate/create')}
          disabled={!isDirty}
          onClick={() => {
            saveConfig({
              configurationData: configurationData,
              configurationDirtyData: configurationDirtyData,
            });
          }}
        >
          Update
        </ClassicButton>
      </AgGridContainer>
    </>
  );
};

export default ConfigurationGrid;
