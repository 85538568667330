import { themeQuartz } from '@ag-grid-community/theming';

const darkTheme = themeQuartz.withParams({
  accentColor: '#C6D5DD',
  backgroundColor: '#1f2836',
  borderRadius: '0px',
  browserColorScheme: 'dark',
  chromeBackgroundColor: {
    ref: 'foregroundColor',
    mix: 0.07,
    onto: 'backgroundColor',
  },
  fontFamily: {
    googleFont: 'Lato',
  },
  foregroundColor: '#FFF',
  headerFontSize: 14,
  oddRowBackgroundColor: '#171A1C',
  wrapperBorderRadius: '0px',
});

export default darkTheme;
