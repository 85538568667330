import axios from 'axios';
import { createDefaultHeaders } from './hooks';

axios.defaults.withCredentials = false;
axios.defaults.maxRedirects = 0;
axios.defaults.headers.common = {
  'Content-Type': 'application/json',
  ...createDefaultHeaders(),
};

export default axios;
