import { CustomCellRendererProps } from '@ag-grid-community/react';
import { formatFromUtc } from 'utils/dateFormating';

const UtcDateFormatCellRenderer = (params: CustomCellRendererProps, timeZone?: string) => {
  if (undefined === params.value) {
    return '-';
  }

  try {
    return formatFromUtc(new Date(params.value), timeZone);
  } catch (error) {
    console.error(params.value, error);
  }
};

export default UtcDateFormatCellRenderer;
