import { themeQuartz } from '@ag-grid-community/theming';

const ligthTheme = themeQuartz.withParams({
  borderRadius: '0px',
  browserColorScheme: 'light',
  columnBorder: false,
  fontFamily: {
    googleFont: 'Lato',
  },
  fontSize: '14px',
  foregroundColor: '#171A1C',
  headerBackgroundColor: '#C6D5DD',
  headerFontSize: 14,
  headerFontWeight: 700,
  headerTextColor: '#171A1C',
  iconSize: '16px',
  oddRowBackgroundColor: '#EDF1F4',
  sidePanelBorder: false,
  wrapperBorder: false,
  wrapperBorderRadius: '0px',
});

export default ligthTheme;
