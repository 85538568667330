const getTenantNameFromUrl = (): string => {
  if (window.location.host.includes('localhost')) {
    return 'local';
  }
  const re = /([\w\-]+)/;
  return (window.location.host.match(re) || ['local'])[0];
};

export const TENANT_NAME = () => {
  try {
    const tenantIdOverride = localStorage.getItem('X-TENANT-ID');
    if (tenantIdOverride) {
      return tenantIdOverride;
    } else {
      return getTenantNameFromUrl();
    }
  } catch (e) {
    return getTenantNameFromUrl();
  }
};

export const HOST_NAME = () => {
  if (window.location.host.includes('localhost')) {
    return process.env.REACT_APP_API_URL;
  }
  const re = /([\w\-]+)\./;
  return window.location.host.replace(re, 'https://$1.api.');
};

export const HOST_GTS_NAME = () => {
  if (window.location.host.includes('localhost')) {
    return process.env.REACT_APP_GTS_URL;
  }
  const re = /([\w\-]+)\./;
  return window.location.host.replace(re, 'https://$1.gts-api.');
};

export const COURIER_ID = (): string => {
  const isDev = ['localhost', 'dev', 'staging', 'test'].some((env) => {
    return window.location.host.toLowerCase().includes(env);
  });

  return isDev ? COURIER_ID_TEST : COURIER_ID_PROD;
};

export const COURIER_ID_TEST = 'OWI2ZWNlOTUtNTUzOS00NjE1LWEzOGQtNjM5NWFlMDkzYmQ1L3Rlc3Q=';
export const COURIER_ID_PROD = 'OWI2ZWNlOTUtNTUzOS00NjE1LWEzOGQtNjM5NWFlMDkzYmQ1';
