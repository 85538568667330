import { useMutation, useQuery, useQueryClient } from 'react-query';
import { HOST_GTS_NAME } from 'utils/constants';
import axios from 'utils/axios';
import {
  Certificate,
  CertificateDetails,
  CertificateUpload,
  Configuration,
  DataCollector,
} from './types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, openModal } from 'store/modalSlice';
import { useHistory } from 'react-router-dom';

export const useCertificates = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const fetchData = (): Promise<Certificate[]> => {
    return axios
      .get<Certificate[]>(`${HOST_GTS_NAME()}/gts/certificates`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        return res.data;
      });
  };

  return useQuery<Certificate[], Error>(['useCertificates'], fetchData, {
    keepPreviousData: false,
  });
};

export const getCertificateByName = (name: string, token: string) => {
  return axios
    .get<CertificateDetails[]>(`${HOST_GTS_NAME()}/gts/certificates/${name}/info`, {
      headers: { Authorization: token },
    })
    .catch((error) => {
      console.log(error);
    });
};

export const useCertificateUpload = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();

  const mutationFn = ({ file, category, name, keystoreType, password }: CertificateUpload) => {
    const formData = new FormData();
    formData.append('certificateFile', file[0]);
    formData.append('category', category);
    formData.append('name', name);
    formData.append('keystoreType', keystoreType.name);

    if (password) {
      formData.append('password', password);
    }

    return axios
      .post(`${HOST_GTS_NAME()}/gts/certificates`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token,
        },
      })
      .then(() => queryClient.invalidateQueries('useCertificates'))
      .then(() => {
        history.push('/app/gts-admin-panel');
      })
      .catch((error) => {
        console.log(error);

        const {
          response: { data },
        } = error;

        if (data) {
          dispatch(
            openModal({
              modalId: 'adminErrorModal',
              props: { errors: data.errors, message: data.message },
            }),
          );
        }
      });
  };

  return useMutation(mutationFn);
};

export const useCertificateRemoval = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.authReducer);
  const queryClient = useQueryClient();

  const mutationFn = (certName: string) => {
    return axios
      .delete(`${HOST_GTS_NAME()}/gts/certificates/${certName}`, {
        headers: { Authorization: token },
      })
      .then(() => {
        dispatch(closeModal({ modalId: 'certificateRemovalConfirmationModal' }));
        queryClient.invalidateQueries('useCertificates');
      })
      .catch((error) => {
        console.log(error);

        const {
          response: { data },
        } = error;

        if (data) {
          dispatch(
            openModal({
              modalId: 'adminErrorModal',
              props: { errors: data.errors, message: data.message },
            }),
          );
        }
      });
  };

  return useMutation(mutationFn);
};

export const useConfigproperty = () => {
  const { token } = useAppSelector((state) => state.authReducer);

  const fetchData = (): Promise<Configuration[]> => {
    return axios
      .get<Configuration[]>(`${HOST_GTS_NAME()}/configproperty`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  };

  return useQuery<Configuration[], Error>(['useConfigproperty'], fetchData, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });
};

export const useDataCollector = () => {
  const { token } = useAppSelector((state) => state.authReducer);

  const fetchData = (): Promise<DataCollector> => {
    return axios
      .get<DataCollector>(`${HOST_GTS_NAME()}/gts/datacollector`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        return {} as DataCollector;
      });
  };

  return useQuery<DataCollector, Error>(['useDataCollector'], fetchData, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetConfigProperty = (key: string) => {
  const { token } = useAppSelector((state) => state.authReducer);

  const fetchData = (): Promise<Configuration> => {
    return axios
      .get<Configuration>(`${HOST_GTS_NAME()}/configproperty/${key}`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return { key: key, value: 'false' } as Configuration;
      });
  };

  return useQuery<Configuration, Error>(['useConfigproperty', key], fetchData, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    enabled: !!token,
  });
};

interface useSaveConfigPropertyParams {
  configurationData: Configuration[];
  configurationDirtyData: string[];
}

export const useSaveConfigproperty = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.authReducer);
  const mutationFn = ({
    configurationData,
    configurationDirtyData,
  }: useSaveConfigPropertyParams) => {
    return new Promise((resolve, reject) => {
      for (const config of configurationData) {
        if (-1 !== configurationDirtyData.indexOf(config.key)) {
          axios
            .put(`${HOST_GTS_NAME()}/configproperty/${config.key}`, config.value, {
              headers: { 'Content-Type': 'text/plain', Authorization: token },
            })
            .then(() => {
              console.log('ok');
            })
            .catch((error) => {
              const {
                response: { data },
              } = error;

              if (data) {
                dispatch(
                  openModal({
                    modalId: 'adminErrorModal',
                    props: { errors: data.errors, message: data.message },
                  }),
                );
              }
              reject();
            });
        }
      }
      resolve(true);
    });
  };

  return useMutation(mutationFn);
};
