import { FC, useContext } from 'react';

import { useEmails, usePhoneNumbers, useTimezoneSetting } from './settingsApi';
import styled from 'styled-components';
import { useAppSelector } from 'store/hooks';
import { TenantInfoContext } from 'App';
import { PhoneNumberForm } from './PhoneNumberForm';
import { EmailForm } from './EmailForm';
import { TimezoneSettingsForm } from './TimezoneSettingsForm';

export const TitleLabel = styled.span`
  display: inline-block;
  font-weight: 600;
  margin-right: 0.3125rem;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const AccountSetting: FC = () => {
  const { data: phoneNumbers } = usePhoneNumbers();
  const { data: emails } = useEmails();

  const { data: timezoneSettings } = useTimezoneSetting();

  const { firstName, lastName } = useAppSelector((state) => state.authReducer);
  const {
    login: { organisation },
  } = useContext(TenantInfoContext);

  return (
    <>
      <InfoRow>
        <TitleLabel>Organisation: </TitleLabel>
        {organisation}
      </InfoRow>
      <InfoRow>
        <TitleLabel>First name: </TitleLabel>
        {firstName}
      </InfoRow>
      <InfoRow>
        <TitleLabel>Last name: </TitleLabel>
        {lastName}
      </InfoRow>
      {phoneNumbers && <PhoneNumberForm phoneNumbers={phoneNumbers.content} />}
      {emails && <EmailForm emails={emails.content} />}

      {timezoneSettings && <TimezoneSettingsForm timezoneSettings={timezoneSettings} />}
    </>
  );
};
