import { FC } from 'react';
import { ButtonWithLoader, ErrorBox, Modal } from 'components';
import styled from 'styled-components';
import { ClassicButton, VARIANT } from 'components/Buttons/ClassicButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'store/modalSlice';
import { useFileUpload, useFileUploadConfig } from '../nominationsApi';
import { WarningIcon } from 'assets/icons';

const UploadModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 3.125rem;
  height: calc(100% - 9rem);
`;
// TODO merge common components for nomination validation popups
const ModalHeading = styled.h2`
  display: flex;
  align-items: center;
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 1rem;

  svg {
    margin-left: 0.5rem;
  }
`;

const ModalParagraph = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 44.875rem;
  display: flex;
  justify-content: space-between;
`;

const ErrorsContainer = styled.div`
  flex: 1;
  margin-bottom: 0.5rem;
  overflow-y: auto;
`;

const externalContentStyles = {
  height: '50%',
  width: '60%',
  padding: '0',
};

export const UploadTimeValidationModal: FC = () => {
  const dispatch = useAppDispatch();
  const { props } = useAppSelector((state) => state.modalReducer.uploadTimeValidationModal);
  const { selectedDate } = useAppSelector((state) => state.globalSlice);
  const currentDate = new Date(selectedDate);
  const { isLoading, mutate: uploadFile } = useFileUpload();
  const { data } = useFileUploadConfig();

  return (
    <Modal
      modalId="uploadTimeValidationModal"
      externalContentStyles={externalContentStyles}
      headerType="warning"
    >
      {props && props.error && props.fileToUpload ? (
        <UploadModalContainer>
          <ModalHeading>
            Confirmation <WarningIcon />
          </ModalHeading>
          <ModalParagraph>{props.error.message}</ModalParagraph>
          {props.error && (
            <ErrorsContainer>
              <ErrorBox errors={props.error.errors.map((errorDetail) => errorDetail.message)} />
            </ErrorsContainer>
          )}

          <ButtonContainer>
            <ButtonWithLoader
              isLoading={isLoading}
              width="10rem"
              variant={VARIANT.SECONDARY}
              onClick={() =>
                uploadFile({
                  file: props.fileToUpload,
                  currentDate: currentDate,
                  ignoreNominationLeadTime: true,
                  importAllDates: true,
                })
              }
            >
              Yes
            </ButtonWithLoader>
            {data?.value === 'true' && (
              <ButtonWithLoader
                isLoading={isLoading}
                width="20rem"
                variant={VARIANT.SECONDARY}
                onClick={() =>
                  uploadFile({
                    file: props.fileToUpload,
                    currentDate: currentDate,
                    ignoreNominationLeadTime: null,
                    importAllDates: false,
                  })
                }
              >
                Import without violating values/dates
              </ButtonWithLoader>
            )}
            <ClassicButton
              disabled={isLoading}
              onClick={() => dispatch(closeModal({ modalId: 'uploadTimeValidationModal' }))}
              width="10rem"
              variant={VARIANT.PRIMARY}
            >
              No
            </ClassicButton>
          </ButtonContainer>
        </UploadModalContainer>
      ) : (
        <></>
      )}
    </Modal>
  );
};
