import { AgCharts } from 'ag-charts-react';
import { AgAxisLabelFormatterParams, AgChartOptions, AgChartTheme } from 'ag-charts-community';
import { usePortfolioImbalanceSignal } from 'features/GTSPanel/gtsdataApi';
import { Loader } from 'components/Loaders';
import { formatCustomFromUtc, formatFromUtc } from 'utils/dateFormating';
import { Prognosis } from './components/Prognosis';
import { useAppSelector } from 'store/hooks';
import { useContext, useEffect, useState } from 'react';
import { ThemeFunctionsContext } from 'App';
import { darkTheme, ligthTheme } from './themes';
import './ImbalanceSignal.css';
import { OperationalPrognosisPos, PortfolioImbalanceSignalResponse } from 'features/GTSPanel/types';

interface ImbalanceSignalProps {
  portfolioEdigasId?: string;
}

const ImbalanceSignal = ({ portfolioEdigasId }: ImbalanceSignalProps) => {
  const { timezone } = useAppSelector((store) => store.globalSlice);
  const [chartData, setChartData] = useState<PortfolioImbalanceSignalResponse>({
    lastUpdated: new Date().toISOString(),
    operationalAccountablePosList: [],
    operationalPrognosisPos: {} as OperationalPrognosisPos,
  });
  const { isLoading, data, isSuccess } = usePortfolioImbalanceSignal(portfolioEdigasId);
  const { themeName } = useContext(ThemeFunctionsContext) || {};
  const [chartTheme, setChartTheme] = useState<AgChartTheme>(ligthTheme);

  useEffect(() => {
    if (!isLoading && data && isSuccess) {
      setChartData(data);
    }
  }, [isLoading, data, isSuccess]);

  useEffect(() => {
    if ('lightTheme' === themeName) {
      setChartTheme(ligthTheme);
    } else {
      setChartTheme(darkTheme);
    }
  }, [themeName]);

  const options: AgChartOptions = {
    theme: chartTheme,
    title: {
      text: 'Portfolio Imbalance Signal',
    },
    footnote: {
      text: `Last updated ${formatFromUtc(
        new Date(chartData.lastUpdated),
        timezone && timezone.timeZone,
      )}`,
    },
    padding: { left: 73 },
    data: chartData?.operationalAccountablePosList,
    series: [
      {
        type: 'bar',
        xKey: 'posDateTime',
        xName: 'Time',
        yKey: 'positionEnergyAmount',
        yName: portfolioEdigasId,
        fill: '#c6d5dd',
      },
    ],
    axes: [
      {
        type: 'number',
        position: 'left',
        title: { text: 'Energy (kWh)' },
        crosshair: {
          enabled: false,
        },
      },
      {
        type: 'time',
        position: 'bottom',
        interval: { step: 3600000 },
        title: { text: 'Time' },
        label: {
          avoidCollisions: true,
          rotation: -45,
          // format: '%H:%M %a',
          formatter: (params: AgAxisLabelFormatterParams) => {
            return formatCustomFromUtc(params.value, timezone?.timeZone, 'HH:mm E');
          },
        },
        crosshair: {
          enabled: false,
        },
        min: chartData?.range && chartData?.range[0],
        max: chartData?.range && chartData?.range[1],
      },
    ],
    legend: {
      enabled: true,
      position: 'bottom',
    },
  };

  return !isLoading && chartData ? (
    <div className="ImbalanceSignalWrapper">
      <AgCharts options={options} />
      {chartData.operationalAccountablePosList.length > 0 && (
        <Prognosis
          lastValueAccountable={
            chartData.operationalAccountablePosList[
              chartData.operationalAccountablePosList.length - 1
            ]
          }
          lastValuePrognosis={chartData.operationalPrognosisPos}
        />
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default ImbalanceSignal;
