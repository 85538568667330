import React, { useRef, FC, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { BalanceGroupValues } from './BalanceGroupValues';
import { BalanceGroupExpandableMenu } from './BalanceGroupExpandableMenu';
import { BalanceGroup } from 'utils/types';
import { MarketAreaScrollPositionContext } from 'features/Nominations/components/MarketArea';

const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
`;

const TableRow = styled.div`
  display: flex;
  height: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  min-width: 0;
  max-width: 119.25rem; //This is width of 24 cells + buttons + spacings between cells - last spacer
`;

export interface BalanceGroupCarouselProps {
  balanceGroup: BalanceGroup;
  connectionId: number;
  areaId: number;
  connectionPointName: string;
  connectionPointIndetifier: string;
}

export const BalanceGroupCarousel: FC<BalanceGroupCarouselProps> = ({
  balanceGroup,
  connectionId,
  areaId,
  connectionPointName,
  connectionPointIndetifier,
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const { scrollPosition, setScrollPosition } = useContext(MarketAreaScrollPositionContext);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = scrollPosition;
    }
  }, [scrollPosition]);

  const alignCarousel = () => {
    if (carouselRef.current) {
      if (carouselRef.current.scrollLeft !== scrollPosition) {
        setScrollPosition(carouselRef.current.scrollLeft);
      }
    }
  };

  return (
    <ContentContainer>
      <BalanceGroupExpandableMenu
        balanceGroup={balanceGroup}
        connectionPointName={connectionPointName}
        connectionPointId={connectionId}
      />
      <CarouselContainer ref={carouselRef}>
        <TableRow>
          <BalanceGroupValues
            balanceGroup={balanceGroup}
            connectionId={connectionId}
            areaId={areaId}
            alignCarousel={alignCarousel}
            connectionPointIndetifier={connectionPointIndetifier}
          />
        </TableRow>
      </CarouselContainer>
    </ContentContainer>
  );
};
