import { FC, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { CarouselStyledCell } from 'components';
import { Version } from '../../types';

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  min-width: 0;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
`;

const TableRow = styled.div`
  display: flex;
  height: 2.5rem;
  max-height: 2.5rem;
`;

const CarouselContainer = styled.div`
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
`;

interface VersionTableCarouselControlProps {
  scrollPosition: number;
  version: Version;
}

export const VersionTableCarousel: FC<VersionTableCarouselControlProps> = ({
  scrollPosition,
  version,
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollLeft = scrollPosition;
    }
  }, [scrollPosition]);

  return (
    <ContentContainer>
      <CarouselContainer ref={carouselRef}>
        <TableRow>
          {version.nominated.timetable.map((versionValue, key) => (
            <CarouselStyledCell key={key}>{versionValue}</CarouselStyledCell>
          ))}
        </TableRow>
      </CarouselContainer>
    </ContentContainer>
  );
};
