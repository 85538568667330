import {
  BaseButton,
  ClassicButton,
  ConfirmationPopupText,
  RemoveCertConfirmationPopup,
  FormContainer,
  FormSection,
  FormTitle,
  HeadingBar,
  VARIANT,
} from 'components';
import { getCertificateByName, useCertificateRemoval, useCertificates } from '../GTSAdminPanelApi';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Certificate, CertificateDetails } from '../types';
import { AgGridReact, CustomCellRendererProps } from '@ag-grid-community/react';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { ColDef, IDetailCellRendererParams, ModuleRegistry } from '@ag-grid-community/core';
import UtcDateFormatCellRenderer from './UtcDateFormatCellRenderer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useHistory } from 'react-router-dom';
import { ThemeFunctionsContext } from 'App';
import ligthTheme from 'features/GTSPanel/components/SystemBalanceSignalTable/themes/ligthTheme';
import darkTheme from 'features/GTSPanel/components/SystemBalanceSignalTable/themes/darkTheme';
import { openModal } from 'store/modalSlice';
import { RemoveIcon } from 'assets/icons';
import styled from 'styled-components';

ModuleRegistry.registerModules([MasterDetailModule]);

export const AgGridContainer = styled.div`
  padding: 1.5625rem 5rem;
  width: 100%;
  margin-bottom: 2rem;
  height: 40vh;
`;

const Certificates = () => {
  const [rowData, setRowData] = useState<Certificate[]>([]);
  const { timezone: userTimezone } = useAppSelector((store) => store.globalSlice);
  const { authorities } = useAppSelector((state) => state.authReducer);
  const isAdmin = authorities.includes('ADMIN') || authorities.includes('SYSADMIN');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.authReducer);

  /**
   * Certificates
   */
  const {
    data: certificates,
    isLoading: isLoadingCert,
    isSuccess: isSuccessCert,
  } = useCertificates();

  useEffect(() => {
    if (!isLoadingCert && isSuccessCert) {
      setRowData(certificates);
    }
  }, [certificates, isLoadingCert, isSuccessCert]);

  // Column Definitions: Defines & controls grid columns.
  const [colDefs] = useState<ColDef<Certificate>[]>([
    { field: 'categoryName', suppressMovable: true, cellRenderer: 'agGroupCellRenderer' },
    { field: 'certificateName', suppressMovable: true },

    { field: 'fileName', suppressMovable: true, flex: 1 },
    { field: 'keystoreType', suppressMovable: true },
    {
      field: 'createdAt',
      suppressMovable: true,
      cellRenderer: (params: CustomCellRendererProps) =>
        UtcDateFormatCellRenderer(params, userTimezone?.timeZone),
    },
    {
      field: 'updatedAt',
      suppressMovable: true,
      sort: 'desc',
      cellRenderer: (params: CustomCellRendererProps) =>
        UtcDateFormatCellRenderer(params, userTimezone?.timeZone),
    },
    {
      headerName: 'Actions',
      cellRenderer: ({ data }: { data: Certificate }) => {
        return (
          <>
            <BaseButton
              width="2.5rem"
              onClick={() =>
                dispatch(
                  openModal({
                    modalId: 'certificateRemovalConfirmationModal',
                    props: {
                      certName: data.certificateName,
                    },
                  }),
                )
              }
            >
              <RemoveIcon />
            </BaseButton>
          </>
        );
      },
    },
  ]);
  const { themeName } = useContext(ThemeFunctionsContext) || {};
  const [gridTheme, setGridTheme] = useState(ligthTheme);

  useEffect(() => {
    if ('lightTheme' === themeName) {
      setGridTheme(ligthTheme);
    } else {
      setGridTheme(darkTheme);
    }
  }, [themeName]);

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          { field: 'alias', maxWidth: 150 },
          { field: 'subjectDN', wrapText: true },
          { field: 'issuerDN' },
          { field: 'serialNumber' },
          { field: 'signatureAlgorithm' },
          {
            field: 'validFrom',
            cellRenderer: (params: CustomCellRendererProps) =>
              UtcDateFormatCellRenderer(params, userTimezone?.timeZone),
            maxWidth: 170,
          },
          {
            field: 'validTo',
            cellRenderer: (params: CustomCellRendererProps) =>
              UtcDateFormatCellRenderer(params, userTimezone?.timeZone),
            maxWidth: 170,
          },
        ],
        defaultColDef: {
          flex: 1,
        },
      },
      getDetailRowData: (params) => {
        getCertificateByName(params.data.certificateName, token).then((res) => {
          res && params.successCallback(res.data);
        });
      },
    } as IDetailCellRendererParams<Certificate, CertificateDetails>;
  }, []);

  return (
    <>
      <HeadingBar>
        <FormTitle>Certificates</FormTitle>
      </HeadingBar>
      <AgGridContainer className={'ag-theme-quartz'}>
        {!isLoadingCert && (
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            theme={gridTheme}
            pagination={false}
            paginationPageSize={12}
            paginationPageSizeSelector={false}
            masterDetail={true}
            detailCellRendererParams={detailCellRendererParams}
          />
        )}
      </AgGridContainer>

      <RemoveCertConfirmationPopup
        confirmationPopupId="certificateRemovalConfirmationModal"
        deleteQuery={useCertificateRemoval}
      >
        <ConfirmationPopupText>Are you sure you want to remove this entry?</ConfirmationPopupText>
      </RemoveCertConfirmationPopup>

      <FormContainer>
        <FormSection>
          <ClassicButton
            width="14.375rem"
            variant={VARIANT.PRIMARY}
            onClick={() => history.push('/app/gts-admin-panel/certificate/create')}
            disabled={!isAdmin}
          >
            Add certificate
          </ClassicButton>
        </FormSection>
      </FormContainer>
    </>
  );
};

export default Certificates;
