import {
  ActionBar,
  ButtonWithLoader,
  ClassicButton,
  ContentContainer,
  ControlledSelectInput,
  FieldContainerWithLabel,
  FormContainer,
  FormSection,
  FormSectionTitle,
  MainContainer,
  StyledTextInput,
  VARIANT,
} from 'components';
import {
  FlexContainerWithSpaceBetween,
  InputCover,
  StyledFileInput,
  StyledFileLabel,
} from '../GTSAdminPanel';
import { FieldValues, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useCertificateUpload } from '../GTSAdminPanelApi';
import { useHistory } from 'react-router-dom';
import { AdminErrorModal } from 'features/AdminPanel/AdminErrorModal';

type AllowedExtensions = '.p12' | '.crt' | null;

const CertificateForm = () => {
  const [fileName, setFileName] = useState('Choose file...');
  const history = useHistory();

  const storeTypes = [
    { id: 1, name: 'KEYSTORE' },
    { id: 2, name: 'TRUSTSTORE' },
  ];
  const { mutate: fileUpload } = useCertificateUpload();
  const {
    register,
    unregister,
    trigger,
    getValues,
    watch,
    control,
    formState: { isDirty, isValid },
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      category: 'GTS',
      name: '',
      keystoreType: storeTypes[1],
      certificateFile: '',
      password: '',
    } as FieldValues,
  });

  const [allowedExtension, setAllowedExtension] = useState<AllowedExtensions>(null);
  const watchKeystoreType = watch('keystoreType');

  useEffect(() => {
    if ('TRUSTSTORE' === watchKeystoreType.name) {
      // setCertNamePlaceholder('GTS-server');
      setValue('name', 'GTS-server');
      unregister('password');
      setAllowedExtension('.crt');
      trigger();
    } else {
      // setCertNamePlaceholder('GTS-client');
      setValue('name', 'GTS-client');
      register('password', {
        required: true,
        minLength: 2,
      });
      setAllowedExtension('.p12');
      trigger();
    }
  }, [register, unregister, watchKeystoreType]);

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget.files?.item(0) || { name: '' };
    if (name.length) {
      setFileName(name);
      trigger();
    }
  };

  const submitForm = () => {
    const { category, name, keystoreType, certificateFile, password } = getValues();
    fileUpload({
      file: certificateFile,
      category,
      name,
      keystoreType,
      certificateFile,
      password,
    });
  };

  return (
    <MainContainer>
      <ContentContainer width="65%">
        <FormContainer>
          <form>
            <FlexContainerWithSpaceBetween>
              <FormSection>
                <FormSectionTitle>Certificate data</FormSectionTitle>

                <StyledTextInput
                  type="text"
                  label="Category name [atm GTS is accepted]"
                  placeholder="GTS"
                  register={register('category', {
                    required: true,
                    minLength: 2,
                  })}
                  width="17.5rem"
                />
                <StyledTextInput
                  type="text"
                  placeholder=""
                  label="Certificate name"
                  register={register('name', {
                    required: true,
                    minLength: 2,
                  })}
                  width="17.5rem"
                />
                <FieldContainerWithLabel>
                  <p>Keystore Type</p>
                  <ControlledSelectInput
                    required
                    control={control}
                    width="19.5rem"
                    placeholder="Choose"
                    options={storeTypes}
                    name="keystoreType"
                  />
                </FieldContainerWithLabel>
                <FieldContainerWithLabel>
                  <p>Cert file for upload</p>
                  <StyledFileLabel>
                    <StyledFileInput
                      {...register('certificateFile', { required: true })}
                      type="file"
                      onChange={onChange}
                      required
                      accept={allowedExtension as string}
                      disabled={null === allowedExtension}
                    />
                    <InputCover>{fileName}</InputCover>
                  </StyledFileLabel>
                </FieldContainerWithLabel>
                {watchKeystoreType && 'KEYSTORE' === watchKeystoreType.name ? (
                  <StyledTextInput
                    type="text"
                    label="Certificate password"
                    placeholder=""
                    register={register('password', {
                      required: true,
                      minLength: 2,
                    })}
                    width="17.5rem"
                  />
                ) : (
                  ''
                )}
              </FormSection>
            </FlexContainerWithSpaceBetween>
          </form>
        </FormContainer>
        <ActionBar>
          <ClassicButton
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={() => history.push('/app/gts-admin-panel/')}
          >
            Cancel
          </ClassicButton>
          <ButtonWithLoader
            width="10rem"
            isLoading={false}
            onClick={() => {
              // trigger();

              submitForm();
            }}
            disabled={!isDirty || !isValid}
          >
            Save
          </ButtonWithLoader>
        </ActionBar>
      </ContentContainer>
      <AdminErrorModal />
    </MainContainer>
  );
};

export default CertificateForm;
