import { createContext, FC, useState } from 'react';
import { MarketAreaType } from '../types';
import { ConnectionPoint } from './ConnectionPoint';
import { MarketAreaHeader } from './MarketAreaHeader';
import { MarketAreaContainer } from './StyledNominationComponents';
import { calculateTimetableSumValues, calculateRowSum, calculateColumnsSum } from '../utils';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { unselectMarkerArea, selectMarkerArea } from '../nominationReduxSlice';
import { SummaryTable } from './SummaryTable';
import styled from 'styled-components';
import { ConnectionPointNominationType, GRANULARITY } from 'utils/types';

const FullSumContainer = styled.div`
  min-width: 33.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  justify-content: space-evenly;
  margin-left: -0.1875rem;
  flex: 1;
`;

const SummaryBoxWithBorder = styled.div<{ themeColor: string }>`
  height: 100%;
  border-bottom: 0.125rem solid ${({ themeColor }) => themeColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem 0 3rem;
`;

const SummaryHeading = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
`;

const Sum = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
`;

const FlexContainer = styled.div`
  display: flex;
`;

interface MarketAreaProps {
  area: MarketAreaType;
  frameColor: string;
  selectedDate: Date;
  numberOfDays: number;
}

export const MarketAreaScrollPositionContext = createContext({
  scrollPosition: 0,
  setScrollPosition: () => console.error('not defined'),
});

const checkDaily = (connectionPoints: ConnectionPointNominationType[]) => {
  return connectionPoints.some((cp) => GRANULARITY.DAILY === cp.granularity);
};

export const MarketArea: FC<MarketAreaProps> = ({
  area,
  frameColor,
  selectedDate,
  numberOfDays,
}) => {
  const dispatch = useAppDispatch();
  const { name, connectionPoints, isSelected, isIndeterminate } = area;
  const dailyGranularity = checkDaily(connectionPoints);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [sectionExpandState, setSectionExpandState] = useState(
    new Array(connectionPoints.length).fill(false),
  );
  const scrollTrigger = (scrollPos: number) => setScrollPosition(scrollPos);

  const { selectFilters } = useAppSelector((state) => state.nominationReducer);
  const connectionPointFilter = selectFilters.find(
    (filter) => filter.filterName === 'connectionPoint',
  );

  const timetableSumValues = calculateTimetableSumValues(connectionPoints);
  const marketAreaSum = calculateRowSum(timetableSumValues);
  const columnSums = calculateColumnsSum(connectionPoints);
  const toggleSelection = () =>
    isSelected
      ? dispatch(unselectMarkerArea({ areaId: area.id }))
      : dispatch(selectMarkerArea({ areaId: area.id }));

  const toggleConnectionPoint = (index: number) => () => {
    const sectionExpandStateCopy = [...sectionExpandState];
    sectionExpandStateCopy[index] = !sectionExpandStateCopy[index];
    setSectionExpandState(sectionExpandStateCopy);
  };

  // TODO remove unnecessary frame color passing
  return (
    <MarketAreaContainer frameColor={frameColor}>
      <MarketAreaHeader
        name={name}
        isSelected={isSelected}
        isIndeterminate={isIndeterminate}
        toggleSelection={toggleSelection}
        areaId={area.id}
        selectedDate={selectedDate}
        numberOfDays={numberOfDays}
        bgColor={frameColor}
        country={area.country.isoAlpha2Code}
      />
      <MarketAreaScrollPositionContext.Provider
        value={{ scrollPosition, setScrollPosition: scrollTrigger }}
      >
        {connectionPoints.map((connectionPoint, key) => {
          if (connectionPointFilter && connectionPointFilter.options.length > 0) {
            return (
              connectionPoint.shouldBeDisplayed &&
              connectionPointFilter.options.findIndex(
                (connectionPointFilteredName) =>
                  connectionPointFilteredName === connectionPoint.name,
              ) > -1 && (
                <ConnectionPoint
                  scrollPosition={scrollPosition}
                  key={`${connectionPoint.id}_${connectionPoint.internalAccounts[0].name}`}
                  connectionPoint={connectionPoint}
                  areaId={area.id}
                  setScrollPosition={scrollTrigger}
                  displayHeading={key === 0 || sectionExpandState[key - 1]}
                  isExpanded={sectionExpandState[key]}
                  setIsExpanded={toggleConnectionPoint(key)}
                />
              )
            );
          } else {
            return (
              connectionPoint.shouldBeDisplayed && (
                <ConnectionPoint
                  scrollPosition={scrollPosition}
                  key={`${connectionPoint.id}_${connectionPoint.internalAccounts[0].name}`}
                  connectionPoint={connectionPoint}
                  areaId={area.id}
                  setScrollPosition={scrollTrigger}
                  displayHeading={key === 0 || sectionExpandState[key - 1]}
                  isExpanded={sectionExpandState[key]}
                  setIsExpanded={toggleConnectionPoint(key)}
                />
              )
            );
          }
        })}
      </MarketAreaScrollPositionContext.Provider>

      <FlexContainer>
        <FullSumContainer>
          <SummaryBoxWithBorder themeColor={frameColor}>
            <SummaryHeading>Total:</SummaryHeading>
            <Sum>{parseFloat(marketAreaSum.toFixed(3))}</Sum>
          </SummaryBoxWithBorder>
        </FullSumContainer>
        <SummaryTable
          dailyGranularity={dailyGranularity}
          timetableValues={columnSums}
          marketName={name}
          setScrollPosition={scrollTrigger}
          scrollPosition={scrollPosition}
        />
      </FlexContainer>
    </MarketAreaContainer>
  );
};
