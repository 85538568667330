import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageFormContainer } from 'components';

import CertificateForm from 'features/GTSAdminPanel/components/CertificateForm';

interface ParamTypes {
  option: 'create' | 'edit';
  id?: string;
}

export const CertificateFormPage: FC = () => {
  const { option, id } = useParams<ParamTypes>();

  return (
    <PageFormContainer>
      <CertificateForm option={option} id={id} />
    </PageFormContainer>
  );
};
