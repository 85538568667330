import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UploadReponse } from 'features/AdminPanel/types';
import { FileUploadResponseModel } from 'features/Nominations/nominationsApi';
import { MasterDataFile } from 'features/Nominations/types';
import { EditMode, MainCustomErrorType, SettingsWindowType } from 'utils/types';
import { CustomErrorType } from 'utils/types/generalTypes';
interface ModalState {
  versionModal: {
    isOpen: boolean;
    props?: {
      sourceId?: number;
      destinationId?: number;
      counterparty?: string;
      balanceGroupName?: string;
      connectionPointName?: string;
      connectionPointId?: number;
      nominationTrackId?: number;
    };
  };
  fileUploadModal: {
    isOpen: boolean;
  };
  submitModal: {
    isOpen: boolean;
  };
  saveModal: {
    isOpen: boolean;
  };
  copyFromModal: {
    isOpen: boolean;
  };
  copyToModal: {
    isOpen: boolean;
  };
  messageDefinitionModal: {
    isOpen: boolean;
    props?: {
      counterpartyName?: string;
    };
  };
  balancegroupConfirmationModal: {
    isOpen: boolean;
  };
  counterpartyConfirmationModal: {
    isOpen: boolean;
  };
  nominationTrackConfirmationModal: {
    isOpen: boolean;
  };
  areaConfirmationModal: {
    isOpen: boolean;
  };
  connectionPointConfirmationModal: {
    isOpen: boolean;
  };
  messageDefinitionConfirmationModal: {
    isOpen: boolean;
  };
  nominationTimeLeadValidationSaveModal: {
    isOpen: boolean;
    props?: {
      message?: string;
      error?: MainCustomErrorType;
      redirectToSubmit?: boolean;
      editMode?: EditMode;
    };
  };
  nominationTimeLeadValidationSubmitModal: {
    isOpen: boolean;
    props?: {
      message?: string;
      error?: MainCustomErrorType;
      sendAll?: boolean;
    };
  };
  nominationTrackDeleteInvalidGasDayConfirmationModal: {
    isOpen: boolean;
    props?: {
      message?: string;
      errors?: Array<CustomErrorType>;
    };
  };
  nominationOptimisticLockingErrorModal: {
    isOpen: boolean;
    props?: {
      message?: string;
      error?: MainCustomErrorType;
      redirectToSubmit?: boolean;
    };
  };
  settingsModal: {
    isOpen: boolean;
    props?: {
      settingWindow?: SettingsWindowType;
    };
  };
  deletionErrorModal: {
    isOpen: boolean;
    props?: {
      errors?: Array<CustomErrorType>;
    };
  };
  connectionPointRemovalConfirmationModal: {
    isOpen: boolean;
    props?: {
      propertyId?: number;
      dependencyId?: number;
    };
  };
  nominationTrackRemovalConfirmationModal: {
    isOpen: boolean;
    props?: {
      propertyId?: number;
      dependencyId?: number;
    };
  };
  certificateRemovalConfirmationModal: {
    isOpen: boolean;
    props: {
      certName: string;
    };
  };
  balanceGroupRemovalConfirmationModal: {
    isOpen: boolean;
    props?: {
      propertyId?: number;
      dependencyId?: number;
    };
  };

  uploadFileWarningModal: {
    isOpen: boolean;
    props?: {
      fileUploadResponse?: FileUploadResponseModel;
    };
  };
  connectionTestResponseModal: {
    isOpen: boolean;
    props?: {
      testUploadResponse?: UploadReponse;
    };
  };
  connectionTestErrorModal: {
    isOpen: boolean;
  };
  submitErrorModal: {
    isOpen: boolean;
    props?: {
      error?: MainCustomErrorType;
    };
  };
  copyToErrorModal: {
    isOpen: boolean;
    props?: {
      error?: MainCustomErrorType;
    };
  };
  adminErrorModal: {
    isOpen: boolean;
    props?: {
      errors?: Array<CustomErrorType>;
      message?: string;
    };
  };
  globalErrorModal: {
    isOpen: boolean;
    props?: {
      errors?: Array<CustomErrorType>;
      message?: string;
    };
  };
  uploadTimeValidationModal: {
    isOpen: boolean;
    props?: {
      error?: MainCustomErrorType;
      fileToUpload?: MasterDataFile;
    };
  };
  resetGasdayConfirmation: {
    isOpen: boolean;
  };
}

const initialState = {
  versionModal: {
    isOpen: false,
    props: {},
  },
  fileUploadModal: {
    isOpen: false,
  },
  submitModal: {
    isOpen: false,
  },
  saveModal: {
    isOpen: false,
  },
  copyFromModal: {
    isOpen: false,
  },
  copyToModal: {
    isOpen: false,
  },
  messageDefinitionModal: {
    isOpen: false,
    props: {},
  },
  balancegroupConfirmationModal: {
    isOpen: false,
  },
  counterpartyConfirmationModal: {
    isOpen: false,
  },
  nominationTrackConfirmationModal: {
    isOpen: false,
  },
  nominationTrackDeleteInvalidGasDayConfirmationModal: {
    isOpen: false,
    props: {},
  },
  areaConfirmationModal: {
    isOpen: false,
  },
  connectionPointConfirmationModal: {
    isOpen: false,
  },
  messageDefinitionConfirmationModal: {
    isOpen: false,
  },
  nominationTimeLeadValidationSaveModal: {
    isOpen: false,
    props: {},
  },
  nominationTimeLeadValidationSubmitModal: {
    isOpen: false,
    props: {},
  },
  nominationOptimisticLockingErrorModal: {
    isOpen: false,
    props: {},
  },
  settingsModal: {
    isOpen: false,
    props: {},
  },
  deletionErrorModal: {
    isOpen: false,
    props: {},
  },
  connectionPointRemovalConfirmationModal: {
    isOpen: false,
    props: {},
  },
  nominationTrackRemovalConfirmationModal: {
    isOpen: false,
    props: {},
  },
  certificateRemovalConfirmationModal: {
    isOpen: false,
    props: {},
  },
  balanceGroupRemovalConfirmationModal: {
    isOpen: false,
    props: {},
  },
  uploadFileWarningModal: {
    isOpen: false,
    props: {},
  },
  connectionTestResponseModal: {
    isOpen: false,
    props: {},
  },
  connectionTestErrorModal: {
    isOpen: false,
  },
  submitErrorModal: {
    isOpen: false,
    props: {},
  },
  copyToErrorModal: {
    isOpen: false,
    props: {},
  },
  adminErrorModal: {
    isOpen: false,
    props: {},
  },
  globalErrorModal: {
    isOpen: false,
    props: {},
  },
  uploadTimeValidationModal: {
    isOpen: false,
    props: {},
  },
  resetGasdayConfirmation: {
    isOpen: false,
  },
} as ModalState;

export type ModalId = keyof typeof initialState;

interface PossibleModalProps {
  sourceId?: number;
  destinationId?: number;
  counterparty?: string;
  certName?: string;
  balanceGroupName?: string;
  counterpartyName?: string;
  connectionPointName?: string;
  connectionPointId?: number;
  message?: string;
  redirectToSubmit?: boolean;
  nominationTrackId?: number;
  error?: MainCustomErrorType;
  editMode?: EditMode;
  settingWindow?: SettingsWindowType;
  errors?: Array<CustomErrorType>;
  propertyId?: number;
  dependencyId?: number;
  fileUploadResponse?: FileUploadResponseModel;
  testUploadResponse?: UploadReponse;
  sendAll?: boolean;
  fileToUpload?: MasterDataFile;
}

interface ModalActionType {
  modalId: ModalId;
  props?: PossibleModalProps;
}

const modalSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    openModal(state, actions: PayloadAction<ModalActionType>) {
      Object.keys(state).forEach((key) => (state[key as ModalId].isOpen = false));
      state[actions.payload.modalId] = {
        isOpen: true,
        props: { ...actions.payload.props },
      };
    },
    closeModal(state, actions: PayloadAction<ModalActionType>) {
      state[actions.payload.modalId] = {
        isOpen: false,
        props: {},
      };
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
