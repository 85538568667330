import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const formatFromUtc = (date: Date, timezone: string | null = null) => {
  const tz = timezone ? timezone : 'Europe/Berlin';
  return formatInTimeZone(date, tz, 'dd.MM.yyyy HH:mm:ss');
};

export const formatDateFromUtc = (date: Date, timezone: string | null = null) => {
  const tz = timezone ? timezone : 'Europe/Berlin';
  return formatInTimeZone(date, tz, 'dd.MM.yyyy');
};

export const formatCustomFromUtc = (
  date: Date,
  timezone: string | null = null,
  formatString = 'dd.MM.yyyy',
) => {
  const tz = timezone ? timezone : 'Europe/Berlin';
  return formatInTimeZone(date, tz, formatString);
};

export const formatTimeFromUtc = (date: Date, timezone: string | null = null) => {
  const tz = timezone ? timezone : 'Europe/Berlin';
  return formatInTimeZone(date, tz, 'HH:mm:ss');
};
export const formatDateWithHour = (date: Date) => format(date, 'dd.MM.yyyy HH:mm:ss');
export const standardDateFormat = (date: Date) => format(date, 'dd.MM.yyyy');
export const formatDateForInput = (date: Date) => format(date, 'yyyy-MM-dd');
export const formatTimeForInput = (date: Date, timezone: string | null = null) => {
  const tz = timezone ? timezone : 'UTC';
  return formatInTimeZone(date, tz, 'HH:mm:ss');
};
