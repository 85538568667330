import {
  BaseButton,
  ActiveIndicator,
  NestedDataExpandButton,
  IdentifierPreviewBox,
} from 'components';
import { FC } from 'react';
import styled from 'styled-components';
import { EditIcon, UserDefinedMasterdataIcon, SystemMasterdataIcon } from 'assets/icons';
import { GridTableCell } from 'components';
import { BalancegroupTable } from './BalancegroupTable';
import { useHistory } from 'react-router-dom';
import { RolePreviewBox } from './RolePreviewBox';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Counterparty } from 'utils/types';
import { toggleSection } from 'store/expandedSectionsSlice';

interface CounterpartyRowProps {
  counterparty: Counterparty;
}

const Row = styled.div<{ ownCounterparty?: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: minmax(13rem, 1fr) minmax(11rem, 1fr) minmax(12rem, 1fr) minmax(14rem, 1fr) 4.5rem 3.125rem 2.5rem;
  grid-column-gap: 0.3125rem;
  margin-bottom: 0.3125rem;
  ${({ ownCounterparty }) => ownCounterparty && 'font-weight: 600!important;'}
`;

const CounterpartyStateInfo = styled.div`
  display: flex;
`;

const BalancegroupsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.darkGrey};
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.white};
`;

const CounterpartyName = styled.span<{ ownCounterparty?: boolean }>`
  ${({ ownCounterparty }) => ownCounterparty && 'font-weight: 600;'}
`;

const CustomGridCell = styled(GridTableCell)<{ ownCounterparty?: boolean }>`
  ${({ ownCounterparty }) => ownCounterparty && 'font-weight: 600;'}
`;

export const CounterpartyRow: FC<CounterpartyRowProps> = ({ counterparty }) => {
  const {
    name,
    code,
    id,
    identifiers,
    roles,
    balanceGroups,
    active,
    userDefined,
    ownCounterparty,
  } = counterparty;
  const history = useHistory();
  const { authorities } = useAppSelector((state) => state.authReducer);
  const expandedSections = useAppSelector((state) => state.expandedSectionsSlice);
  const dispatch = useAppDispatch();
  const toggleRow = () => {
    if (id) {
      dispatch(toggleSection({ section: 'masterdata', sectionId: id }));
    }
  };
  const isExpanded = expandedSections.masterdata.includes(id || 0);
  const isAdmin = authorities.includes('ADMIN') || authorities.includes('SYSADMIN');
  return id ? (
    <>
      <Row ownCounterparty={ownCounterparty}>
        <CustomGridCell align="space-between">
          <CounterpartyName ownCounterparty={ownCounterparty}>{name}</CounterpartyName>
          <CounterpartyStateInfo>
            {userDefined ? <UserDefinedMasterdataIcon /> : <SystemMasterdataIcon />}
            <BalancegroupsCount>{balanceGroups?.length}</BalancegroupsCount>
          </CounterpartyStateInfo>
        </CustomGridCell>
        <CustomGridCell ownCounterparty={ownCounterparty}>{code}</CustomGridCell>
        <CustomGridCell ownCounterparty={ownCounterparty} align="space-between" overflowEnabled>
          {identifiers.length > 0 ? (
            <>
              {identifiers[0].identifier}
              <IdentifierPreviewBox identifiers={identifiers} />
            </>
          ) : (
            <></>
          )}
        </CustomGridCell>

        <CustomGridCell ownCounterparty={ownCounterparty} align="space-between" overflowEnabled>
          {roles[0].role.role}
          <RolePreviewBox roles={roles} />
        </CustomGridCell>

        <CustomGridCell ownCounterparty={ownCounterparty} align="center">
          <ActiveIndicator isActive={active} />
        </CustomGridCell>
        <CustomGridCell ownCounterparty={ownCounterparty} align="center">
          <BaseButton
            onClick={() => history.push(`/app/masterdata/counterparty/edit/${id}`)}
            disabled={!isAdmin}
          >
            <EditIcon />
          </BaseButton>
        </CustomGridCell>
        <NestedDataExpandButton isExpanded={isExpanded} setIsExpanded={toggleRow} />
      </Row>
      {isExpanded && (
        <BalancegroupTable
          counterpartyName={name}
          isAdmin={isAdmin}
          balanceGroups={balanceGroups}
          counterpartyId={id}
        />
      )}
    </>
  ) : (
    <></>
  );
};
