import { FC } from 'react';
import ImbalanceSignal from './components/ImbalanceSignal/ImbalanceSignal';
import { usePortfolio } from './gtsdataApi';
import { FormTitle, HeadingBar, Loader } from 'components';
import SystemBalanceSignalGraphic from './components/SystemBalanceSignalGraphic/SystemBalanceSignalGraphic';
import SystemBalanceSignalTable from './components/SystemBalanceSignalTable/SystemBalanceSignalTable';
import { AdminErrorModal } from 'features/AdminPanel/AdminErrorModal';

interface GTSPanelProps {
  option?: 'edit' | 'create';
  areaId?: string;
}

export const GTSPanel: FC<GTSPanelProps> = () => {
  const { isLoading, data } = usePortfolio();

  return !isLoading ? (
    <>
      <div style={{ padding: '3rem' }}>
        <HeadingBar>
          <FormTitle>GTS B2B Services</FormTitle>
        </HeadingBar>
        <ImbalanceSignal portfolioEdigasId={data && data.portfolioEdigasId} />
        <SystemBalanceSignalGraphic />
        <SystemBalanceSignalTable />
      </div>
      <AdminErrorModal />
    </>
  ) : (
    <Loader />
  );
};
