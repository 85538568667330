import { IMessage, useInbox } from '@trycourier/react-inbox';
import { ClassicButton, VARIANT } from 'components/Buttons/ClassicButton';
import { FlexContainer } from 'components/FormStyledComponents';
import { StatusIndicator } from 'components/StatusIndicator';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { MessageStatus } from 'utils/types';

const MessageContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.messageContainerBg};
  `}
  display: flex;
  border-radius: 4px;
  padding: 0.5rem;
  justify-content: space-between;
  height: 3.5rem;
  margin: 0.5rem 0.5rem 0 0.5rem;

  svg {
    margin-right: 0.5rem;
  }
`;

const StyledReadButton = styled(ClassicButton)`
  padding: 0.25rem 1rem;
`;

export const InboxMessage: FC<IMessage> = ({ messageId, read, title, trackingIds }) => {
  const { markMessageRead } = useInbox();

  const [status, ...message] = title && title !== null ? title.split(' ') : ['', ''];

  return (
    <MessageContainer>
      <FlexContainer>
        <StatusIndicator status={status as MessageStatus} />
        {message.join(' ')}
      </FlexContainer>
      {trackingIds?.readTrackingId && !read && (
        <StyledReadButton
          variant={VARIANT.SECONDARY}
          onClick={() => markMessageRead(messageId, trackingIds?.readTrackingId)}
        >
          Mark as read
        </StyledReadButton>
      )}
    </MessageContainer>
  );
};
