import { useQuery } from 'react-query';
import { Counterparty } from 'utils/types';
import { HOST_GTS_NAME } from 'utils/constants';
import axios from 'utils/axios';
import {
  OperationalPrognosisPos,
  OperationalPrognosisSbs,
  Portfolio,
  PortfolioImbalanceSignalResponse,
  SystemBalanceSignalGraphicResponse,
  SystemBalanceSignalTableResponse,
} from './types';
import { openModal } from 'store/modalSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
export interface Counterparties {
  content: Array<Counterparty>;
  totalPages: number;
}

export const useSystemBalanceSignalTable = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.authReducer);

  const fetchData = (): Promise<SystemBalanceSignalTableResponse | null> => {
    return axios
      .get<SystemBalanceSignalTableResponse>(`${HOST_GTS_NAME()}/gts/systembalancesignal/table`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        if (0 === res.data.systemBalanceSignalTable.length) {
          return null;
        }

        res.data.systemBalanceSignalTable = res.data.systemBalanceSignalTable.map((e) => ({
          ...e,
          sbsDateTime: new Date(e.sbsDateTime).getTime(),
          bufferZone04BorderMin: -80000000,
          bufferZone04BorderMax: 80000000,
        }));

        return res.data;
      })
      .catch((error) => {
        const {
          response: { data },
        } = error;

        if (data) {
          dispatch(
            openModal({
              modalId: 'adminErrorModal',
              props: { errors: data.errors, message: data.message },
            }),
          );
        }
        return null;
      });
  };

  return useQuery<SystemBalanceSignalTableResponse | null, Error>(
    ['SystemBalanceSignalTable'],
    fetchData,
    {
      keepPreviousData: true,
    },
  );
};

export const useSystemBalanceSignalGraphic = () => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.authReducer);
  const fetchData = (): Promise<SystemBalanceSignalGraphicResponse> => {
    return axios
      .get<SystemBalanceSignalGraphicResponse>(
        `${HOST_GTS_NAME()}/gts/systembalancesignal/graphic`,
        {
          headers: { Authorization: token },
        },
      )
      .then((res) => {
        res.data.systemBalanceSignalTable = res.data.systemBalanceSignalTable.map((e) => ({
          ...e,
          sbsDateTime: new Date(e.sbsDateTime).getTime(),
          bufferZone04BorderMin: -80000000,
          bufferZone04BorderMax: 80000000,
        }));

        return res.data;
      })
      .catch((error) => {
        const {
          response: { data },
        } = error;

        if (data) {
          dispatch(
            openModal({
              modalId: 'adminErrorModal',
              props: { errors: data.errors, message: data.message },
            }),
          );
        }

        return {
          lastUpdated: '2000-01-01T00:00:00.000000Z',
          operationalPrognosisSbs: {} as OperationalPrognosisSbs,
          systemBalanceSignalTable: [],
        } as SystemBalanceSignalGraphicResponse;
      });
  };

  return useQuery<SystemBalanceSignalGraphicResponse, Error>(
    ['SystemBalanceSignalGraphic'],
    fetchData,
    {
      keepPreviousData: true,
    },
  );
};

export const usePortfolioImbalanceSignal = (portfolioEdigasId: string | undefined) => {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.authReducer);
  const fetchData = (): Promise<PortfolioImbalanceSignalResponse> => {
    return axios
      .get<PortfolioImbalanceSignalResponse>(
        `${HOST_GTS_NAME()}/gts/portfolioimbalancesignal/graphic?portfolioId=${portfolioEdigasId}`,
        {
          headers: { Authorization: token },
        },
      )
      .then((res) => {
        res.data.operationalAccountablePosList = res.data.operationalAccountablePosList.map(
          (e) => ({
            ...e,
            origPosDateTime: e.posDateTime,
            posDateTime: new Date(e.posDateTime).getTime(),
          }),
        );

        const times = res.data.operationalAccountablePosList.map((e) => +e.posDateTime);
        res.data.range = [Math.min(...times), Math.max(...times)];

        return res.data;
      })
      .catch((error) => {
        const {
          response: { data },
        } = error;

        if (data) {
          dispatch(
            openModal({
              modalId: 'adminErrorModal',
              props: { errors: data.errors, message: data.message },
            }),
          );
        }

        return {
          lastUpdated: '2000-01-01T00:00:00.000000Z',
          operationalAccountablePosList: [],
          operationalPrognosisPos: {} as OperationalPrognosisPos,
        } as PortfolioImbalanceSignalResponse;
      });
  };

  return useQuery<PortfolioImbalanceSignalResponse, Error>(
    ['PortfolioImbalanceSignal'],
    fetchData,
    {
      keepPreviousData: true,
      enabled: !!portfolioEdigasId,
    },
  );
};

export const usePortfolio = () => {
  const { token } = useAppSelector((state) => state.authReducer);
  const fetchData = (): Promise<Portfolio> => {
    return axios
      .get<Portfolio[]>(`${HOST_GTS_NAME()}/gts/portfolios`, {
        headers: { Authorization: token },
      })
      .then((res) => {
        return res.data[0];
      });
  };

  return useQuery<Portfolio, Error>(['Portfolio'], fetchData, {
    keepPreviousData: true,
  });
};
