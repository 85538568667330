import { FC, useState } from 'react';
import { GasDayHourDefinitionType, HourlyStatus, MessageStatus } from 'utils/types';
import { CarouselControl } from 'components';
import { ValuesInternalCarousel } from './ValuesCarousel';

import styled from 'styled-components';
import { Values } from 'utils/types/generalTypes';

const CarouselSpacer = styled.div`
  min-width: 2.625rem;
  max-width: 2.625rem;
  height: 2.625rem;

  &:last-child {
    border: none;
  }
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrey};
  :first-child {
    border: none;
  }
  :last-child {
    border: none;
  }
`;
// TODO: common components ...
const StyledCell = styled.div<{ maxWidth?: string }>`
  color: ${({ theme }) => theme.baseBlack};
  background-color: ${({ theme }) => theme.qualityStatusCellBg};
  margin-right: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.875rem;
  height: 2.5rem;
  flex: 1;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};

  &:last-child {
    margin-right: 0;
  }
`;

const StyledCellLarge = styled(StyledCell)`
  justify-content: left;
  text-align: left;
  width: 5rem;
  height: 2.5rem;
  padding: 0.5rem;
`;

const StyledTableHeadingCells = styled(StyledCell)`
  min-width: 13rem;
  background-color: ${({ theme }) => theme.messagePreviewTableHeadingBg};
`;

const QualityStatusInfoContainer = styled.div`
  display: flex;
  min-width: 13rem;
  flex: 1;
  margin-right: 0.25rem;
`;

const CarouselContainer = styled.div`
  margin: 0 1rem;
  padding: 1rem 0;
  border-top: 1px solid ${({ theme }) => theme.tableDividingLine};
`;

interface MessageCompareCarousel {
  externalHourlyValues: Array<Values>;
  internalHourlyValues: Values;
  gasDayHourDefinition: GasDayHourDefinitionType;
}
export const MessageCompareCarousel: FC<MessageCompareCarousel> = ({
  internalHourlyValues,
  externalHourlyValues,
  gasDayHourDefinition,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const emptyStatus: HourlyStatus = {
    timetable: internalHourlyValues?.hourlyStatus.timetable,
  };

  const myClonedArray: MessageStatus[] = [];
  emptyStatus.timetable?.forEach(() =>
    myClonedArray.push(Object.assign({}, MessageStatus.SUCCESS)),
  );
  emptyStatus.timetable = myClonedArray;

  const triggerScroll = (newScrollPosition: number) => {
    setScrollPosition(newScrollPosition);
  };
  return (
    <CarouselContainer>
      <RowContainer>
        <StyledTableHeadingCells>Quality status</StyledTableHeadingCells>
        <CarouselControl
          scrollControl={triggerScroll}
          gasDayHourDefinition={gasDayHourDefinition}
        />
      </RowContainer>
      {internalHourlyValues != null ? (
        <RowContainer title={internalHourlyValues.originTimeSeriesType.definition}>
          <QualityStatusInfoContainer>
            <StyledCell maxWidth="4.5rem">
              {internalHourlyValues.originTimeSeriesType.type}
            </StyledCell>
            <StyledCellLarge>{internalHourlyValues.originTimeSeriesType.title}</StyledCellLarge>
          </QualityStatusInfoContainer>
          <CarouselSpacer />
          <ValuesInternalCarousel scrollPosition={scrollPosition} values={internalHourlyValues} />

          <CarouselSpacer />
        </RowContainer>
      ) : (
        false
      )}
      {externalHourlyValues
        .sort((a, b) => (a.originTimeSeriesType.type > b.originTimeSeriesType.type ? 1 : -1))
        .map((tsMessageValue) => (
          <RowContainer
            key={tsMessageValue.originTimeSeriesType.type}
            title={tsMessageValue.originTimeSeriesType.definition}
          >
            <QualityStatusInfoContainer>
              <StyledCell maxWidth="4.5rem">{tsMessageValue.originTimeSeriesType.type}</StyledCell>
              <StyledCellLarge>{tsMessageValue.originTimeSeriesType.title}</StyledCellLarge>
            </QualityStatusInfoContainer>

            <CarouselSpacer />
            <ValuesInternalCarousel
              scrollPosition={scrollPosition}
              values={tsMessageValue}
              // hourlyStatus={
              //   tsMessageValue.hourlyStatus == null ? emptyStatus : tsMessageValue.hourlyStatus
              // }
            />
            <CarouselSpacer />
          </RowContainer>
        ))}
    </CarouselContainer>
  );
};
