import { FC } from 'react';
import { GTSPanel } from 'features/GTSPanel';
import { PageContainer } from 'components';

export const GTSPage: FC = () => {
  return (
    <PageContainer>
      <GTSPanel />
    </PageContainer>
  );
};
