import { FC } from 'react';
import { PageFormContainer } from 'components';
import { GTSAdminPanel } from 'features/GTSAdminPanel/GTSAdminPanel';

export const GTSAdminPage: FC = () => {
  return (
    <PageFormContainer>
      <GTSAdminPanel />
    </PageFormContainer>
  );
};
