import { FC, ReactNode } from 'react';
import { Modal } from '../Modal';
import { ClassicButton, VARIANT } from '../Buttons/ClassicButton';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, ModalId } from 'store/modalSlice';
import { ButtonWithLoader } from 'components/Buttons/ButtonWithLoader';
import { ButtonsContainer, ConfirmationPopupContentContainer, StyledHeading } from './styled';
import { UseMutationResult } from 'react-query';

// This is hack so we can type-safely retrieve the modal props from the store
type ListOfPossibleDeletionConfirmationModals = 'certificateRemovalConfirmationModal';

interface RemoveCertConfirmationPopupProps {
  confirmationPopupId: ModalId;
  cancelButtonCopy?: string;
  confirmButtonCopy?: string;
  deleteQuery: (certName: string) => UseMutationResult<void, unknown, string, unknown>;
  children: ReactNode;
}

export const RemoveCertConfirmationPopup: FC<RemoveCertConfirmationPopupProps> = ({
  confirmationPopupId,
  cancelButtonCopy,
  confirmButtonCopy,
  deleteQuery,
  children,
}) => {
  const dispatch = useAppDispatch();
  const { props } = useAppSelector(
    ({ modalReducer }) =>
      modalReducer[confirmationPopupId as ListOfPossibleDeletionConfirmationModals],
  );
  const onClose = () => {
    dispatch(closeModal({ modalId: confirmationPopupId }));
  };

  const { mutate: deleteAction, isLoading } = deleteQuery(props.certName);

  return (
    <Modal
      modalId={confirmationPopupId}
      externalContentStyles={{ height: '22.5rem', width: '37.5rem' }}
    >
      <ConfirmationPopupContentContainer>
        <div>
          <StyledHeading>Confirmation</StyledHeading>
          {children}
        </div>
        <ButtonsContainer>
          <ClassicButton
            width="10rem"
            variant={VARIANT.SECONDARY}
            onClick={onClose}
            disabled={isLoading}
          >
            {cancelButtonCopy ? cancelButtonCopy : 'Cancel'}
          </ClassicButton>
          {props?.certName && (
            <ButtonWithLoader
              isLoading={isLoading}
              width="10rem"
              onClick={() => props.certName && deleteAction(props.certName)}
            >
              {confirmButtonCopy ? confirmButtonCopy : 'Submit'}
            </ButtonWithLoader>
          )}
        </ButtonsContainer>
      </ConfirmationPopupContentContainer>
    </Modal>
  );
};
