import { ClassicButton, ErrorBox, SmallLoader, VARIANT } from 'components';
import { FC } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ControlledTimezoneSelectInput } from 'components/TimezoneSelectInput';

import timezonesList, { TimeZone } from 'timezones-list';
import { UserTimezoneSettings, useTimezoneSettingSave } from './settingsApi';
import { MainCustomErrorType } from 'utils/types/generalTypes';

const Loader = styled(SmallLoader)`
  margin: 0 auto;
`;

const HeadingBar = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0 0 0;
  padding: 2.5rem 0 1rem 0;
  border-top: 1px solid ${({ theme }) => theme.milkGrey};
  width: 100%;
`;

const FormTitle = styled.h1`
  color: ${({ theme }) => theme.baseBlack};
  font-size: 1.5rem;
  font-weight: 600;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.mainBackgroundColor};
`;

interface TimezoneSettingsFormProps {
  timezoneSettings: UserTimezoneSettings;
}

export const TimezoneSettingsForm: FC<TimezoneSettingsFormProps> = ({ timezoneSettings }) => {
  const {
    mutate: timezoneMutation,
    isLoading: timezoneSavingInProgres,
    error,
    isSuccess,
    reset: mutationReset,
  } = useTimezoneSettingSave();

  const submitChanges = () => {
    const { timezone } = getValues();
    timezoneMutation(timezone);
    reset({}, { keepValues: true });
    setTimeout(mutationReset, 1000);
  };

  const currentTimezone = timezonesList.find(
    (e) => e.tzCode === timezoneSettings.timeZone,
  ) as TimeZone;

  const {
    control,
    formState: { isDirty, isValid },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      timezone: currentTimezone,
      usedForCorrespondence: false,
    } as FieldValues,
    mode: 'all',
  });

  return (
    <>
      <HeadingBar>
        <FormTitle>Timezone settings</FormTitle>
      </HeadingBar>
      <RowContainer>
        <ControlledTimezoneSelectInput
          placeholder="Timezone (default CET, UTC+01:00)"
          name="timezone"
          control={control}
          width="100%"
          required
          options={timezonesList}
        />

        <ClassicButton
          width="12rem"
          onClick={submitChanges}
          variant={VARIANT.PRIMARY}
          disabled={!(isDirty && isValid) || timezoneSavingInProgres}
          margin="0 0 0 1rem"
        >
          {timezoneSavingInProgres ? <Loader /> : isSuccess ? 'Saved!' : 'Submit changes'}
        </ClassicButton>
      </RowContainer>
      {error && (
        <RowContainer style={{ marginTop: '1rem' }}>
          <ErrorBox errors={(error as MainCustomErrorType).errors.map((error) => error.message)} />
        </RowContainer>
      )}
    </>
  );
};
