import { AgCharts } from 'ag-charts-react';
import { AgAxisLabelFormatterParams, AgChartOptions, AgChartTheme } from 'ag-charts-community';
import 'ag-charts-enterprise';
import { useSystemBalanceSignalGraphic } from 'features/GTSPanel/gtsdataApi';
import { Loader } from 'components/Loaders';
import { formatCustomFromUtc, formatFromUtc } from 'utils/dateFormating';
import './SystemBalanceSignalGraphic.css';
import { useAppSelector } from 'store/hooks';
import { useContext, useEffect, useState } from 'react';
import { ThemeFunctionsContext } from 'App';
import { darkTheme, ligthTheme } from './themes';

const SystemBalanceSignalGraphic = () => {
  const { isLoading, data } = useSystemBalanceSignalGraphic();
  const { timezone } = useAppSelector((store) => store.globalSlice);

  const { themeName } = useContext(ThemeFunctionsContext) || {};
  const [chartTheme, setChartTheme] = useState<AgChartTheme>(ligthTheme);

  useEffect(() => {
    if ('lightTheme' === themeName) {
      setChartTheme(ligthTheme);
    } else {
      setChartTheme(darkTheme);
    }
  }, [themeName]);

  const options: AgChartOptions = {
    theme: chartTheme,
    title: {
      text: `System Balance Signal`,
    },
    footnote: {
      text:
        data?.lastUpdated &&
        `Last updated ${formatFromUtc(new Date(data.lastUpdated), timezone?.timeZone)}`,
    },
    data: !isLoading && data ? data.systemBalanceSignalTable : [],
    padding: {
      right: 40,
    },
    series: [
      {
        type: 'range-area',
        xKey: 'sbsDateTime',
        xName: 'Time',
        yLowKey: 'bufferZone04BorderMin',
        yHighKey: 'bufferZone04BorderMax',
        yLowName: 'Border min',
        yHighName: 'Border max',
        stroke: 'rgba(229, 115, 115, 0.2)',
        fill: 'rgba(229, 115, 115, 0.2)',
        yName: 'Red zone',
        showInLegend: false,
      },
      {
        type: 'range-area',
        xKey: 'sbsDateTime',
        xName: 'Time',
        yLowKey: 'bufferZone03BorderMin',
        yHighKey: 'bufferZone03BorderMax',
        yLowName: 'Border min',
        yHighName: 'Border max',
        stroke: 'rgba(255, 183, 77, 0.3)',
        fill: 'rgba(255, 183, 77, 0.3)',
        yName: 'Low Border orange zone',
        showInLegend: false,
      },
      {
        type: 'range-area',
        xKey: 'sbsDateTime',
        xName: 'Time',
        yLowKey: 'bufferZone02BorderMin',
        yHighKey: 'bufferZone02BorderMax',
        yLowName: 'Border min',
        yHighName: 'Border max',
        stroke: 'rgba(174, 213, 129, 0.5)',
        fill: 'rgba(174, 213, 129, 0.5)',
        yName: 'Low Border light green zone',
        showInLegend: false,
      },
      {
        type: 'range-area',
        xKey: 'sbsDateTime',
        xName: 'Time',
        yLowKey: 'bufferZone01BorderMin',
        yHighKey: 'bufferZone01BorderMax',
        yLowName: 'Border min',
        yHighName: 'Border max',
        stroke: 'rgba(92, 184, 92, 0.7)',
        fill: 'rgba(92, 184, 92, 0.7)',
        yName: 'Low Border dark green zone',
        showInLegend: false,
      },
      {
        type: 'line',
        xKey: 'sbsDateTime',
        yKey: 'positionEnergyAmount',
        stroke: '#455A64',
        marker: { shape: 'triangle', fill: '#FFF', stroke: '#000', strokeWidth: 0.5 },
        yName: 'SBS Accountable',
      },
      {
        type: 'line',
        xKey: 'sbsDateTime',
        yKey: 'sumCausersEnergyAmount',
        stroke: '#D32F2F',
        marker: { shape: 'circle', fill: '#ff0000' },
        yName: 'Sum Causers',
      },
      {
        type: 'line',
        xKey: 'sbsDateTime',
        yKey: 'sumHelpersEnergyAmount',
        stroke: '#64B5F6',
        marker: { shape: 'circle', stroke: '#1a1adb', fill: '#1a1adb' },
        yName: 'Sum Helpers',
      },
    ],
    axes: [
      {
        type: 'number',
        position: 'left',
        max: 80000000,
        min: -80000000,
        interval: { step: 100 },
        title: { text: 'Energy (kWh)' },
      },
      {
        type: 'time',
        position: 'bottom',
        interval: { step: 3600000 },
        title: { text: 'Time' },
        label: {
          format: '%H:%M %a',
          rotation: -45,
          formatter: (params: AgAxisLabelFormatterParams) => {
            return formatCustomFromUtc(params.value, timezone?.timeZone, 'HH:mm E');
          },
        },
      },
    ],
    legend: {
      enabled: true,
      position: 'bottom',
    },
  };

  return !isLoading && data ? (
    <div className="SystemBalanceSignalGraphicWrapper">
      <AgCharts options={options} />
    </div>
  ) : (
    <Loader />
  );
};

export default SystemBalanceSignalGraphic;
